/* 
.header-container {
    background-color: aqua;
}

.logo-container {
    background-color:bisque;
}

.menus-container {
    background-color: blueviolet;
}

.login-button-container {
    background-color: azure;
}

.navbar-nav {
    background-color: blueviolet;
} */

/* toggle button */

.btn-secondary {
    color: white !important
}

.theme-hover:hover {
    color: #F75C03 !important;
    text-decoration: underline;
}

a {
    text-decoration: none;
    cursor: pointer;
   }


.navbar {
    /* background-color: aqua; */
    height: 65px;
    background-color: #f5f5f5;
   
}

.text-gray{
    color: gray;
}

.fs-small {
    font-size: 14px;
}

.fs-smaller {
    font-size: 12px;
}

body {
    background-color: #f5f5f5;
}

.badge-outline {
    background-color: transparent;
    border: 1px solid #191919 !important;
    color: #191919;
    /* font-weight:400 !important; */
}

/* Home page About component */

.about-root-container {
    margin-top: 80px;
}


.right-img {
    background-image: url(./img/road-trip.png);
    background-repeat: no-repeat;
    background-size:cover;
    border-radius: 0 24px 24px 0;
    background-position: center;
}

/* Home page Search component */

.search-box {
    margin-top: -70px;
    position: relative;
}

/* Home carousel */
.carousel-control-next {
    width: 80px;
}
.carousel-control-prev {
    width: 80px;
}


/* Plan details */

.hover-text:hover {
    color: white;
}

.accordion-item {
    border: none;
}

/* Add plan */

.drop-zone {
    width: 100%;
    background-color: #f1f1f1;
    height: 200px;

}


.drop-over {
    background-color: #ffff;
}

.drop-zone-thump {
    width: 230px;
    height: 150px;
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;
    background-size: cover;
    position: relative;
}


.drop-zone-thump::after {
    content: attr(data-label);
    position: absolute;
    bottom:0;
    left: 0;
    width: 100%;
    padding: 5px 0;
    color: #ffff;
    background-color: rgba(0, 0, 0, 0.3);
    font-size: 14px;
    text-align: center;
}

.btn-gray {
    background-color: #6b6b6b;
    color: white;
}

.add-activity a{
    text-decoration: underline;
    
    
}
.add-activity a:hover{
    background-color: #f1f1f1;

}

.dropdown ul {
    min-width: 6rem;
}

.btn-link:hover {
    background-color: #f1f1f1;
}

/* Myplan */

.card-menus {
    position: absolute;
    z-index: 2;
    right: 25px;
    top: 15px;
    
    
}

/* login */

.login-container {
    background-color: #ffff;
}

.btn-google {
    color: rgb(63, 63, 63);
    border: 1px solid lightgray;
}
.btn-facebook {
    border: 1px solid #1877F2;
    background-color: #1877F2;
}

.btn-line {
    border: 1px solid #06C755;
    background-color: #06C755;
}


/* footer */

.footer-container {
    height: 350px;
    
}

.menus ul {
    list-style: none;
}

.bg-light-gray {
    background-color: #f4f4f4;
}

.filter-container {
    z-index: 1;
}





@media only screen and (min-width: 991px) {
    .about-root-container {
        margin-top: 100px;
    }

    .search-box {
        margin-top: -40px;
    }
    .footer-container {
        height: 250px;
    }
    .login-container {
        width: 450px;
        padding: 25px 30px !important;
        margin: 150px 0 50px 0;
    }
    
   
}


